import api from './axios'
import qs from 'qs'
import Toast from '@/components/UI/Toast'
import router from '@/router'
//  登录成功处理


function liveState() {
    return new Promise((resolve, reject) => {
        api
            // .get("/M/Discover/VideoRealtime")
            .get("/M/Discover/getLivingVideo")
            .then((res) => {
                //如果没有正在直播的，直接跳列表
                if (res.data.success) {
                    //console.log(res.data);
                    if(res.data.code == 0){
                        router.push({
                            path: '/course'
                        })
                    }else{
                        const courseId = res.data.id;
                        router.push({
                            path: '/videoRealtime',
                            query: { id:courseId }
                        })
                    }

                    resolve(res)
                }
                resolve(res)
            })
            .catch((e) => {
                resolve('err')
                console.log(e);
            });
        return false
    })
}
export default liveState
